.form-select-div-s {
  width: 390px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 480px) {
  .form-select-div-s {
    width: 320px;
  }
}
