.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  opacity: 0;

  transition: opacity 0.3s ease-in-out;
}

.toast.success {
  background-color: #4caf50; /* Green color for success */
}

.toast.error {
  background-color: #f44336; /* Red color for error */
}

.toast.show {
  opacity: 1;
}
