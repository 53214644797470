/* Example CSS for the Hero component */
.hero {
  margin-top: 0px;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.hero-slide {
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Hide any image overflow */
  position: relative;
  background: linear-gradient(pink, orange, purple);
}
.hero-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
}
.hero-content h1 {
  font-size: 60px;
  font-weight: 600;
  /* font-style: italic; */
  color: #e53238;
  /* margin-bottom: 1rem; */
}
.read-btn {
  border-radius: 2px solid;
  background: linear-gradient(rgb(130, 0, 39), #d63d0e);
  /* font-size: medium; */
  text-align: center;
  width: 200px;
  height: 8vh;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: white;
  margin-top: 30px;
  border-radius: 4px;
  cursor: pointer;
}
.btn-read {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.read-btn:hover {
  background-color: green;
  border-color: greenyellow;
  color: white;
}
.hero-content h2 {
  font-size: 60px;

  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  color: #e53238;
}
@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: xx-large;
    font-weight: 700;
    /* font-style: italic; */
    color: #e53238;
    /* margin-bottom: 1rem; */
  }
  .hero-content h2 {
    font-size: xx-large;

    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    color: #e53238;
  }
}
@media (max-width: 768px) {
  .hero {
    margin-top: -50px;
  }
}
@media (max-width: 480px) {
  .hero {
    margin-top: -50px;
  }
  .hero-slide {
    width: 100%;
    height: 100vh;
    overflow: none; /* Hide any image overflow */
    position: none;
    /* background: linear-gradient(pink, orange, purple); */
  }
  .hero-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
